<script>
import Form, { formCreate } from '../../../../../components/form';
import AddClock from './components/add_clock.vue';
import request from '../../../../../utils/request';

formCreate.component('AddClock', AddClock);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: true,
      },
    };
  },
  methods: {
    submit() {
      const formData = this.getFormData();
      this.fApi.method('AddClock', 'submitForm')().then(() => {
        if (formData.AddClock) {
          const requestUrl = '/sfa/sfaSignFormsController/updateSignRecord';
          const requestParam = {
            posCode: formData.AddClock.positionCode,
            ruleDate: formData.AddClock.ruleDate,
            ruleCode: formData.AddClock.ruleCode,
            exceptionRemarks: formData.AddClock.exceptionRemarks,
            sfaAddSignTypeRespVoList: formData.AddClock.clockType,
          };
          request.post(requestUrl, requestParam)
            .then((res) => {
              this.$message.success(res.message);
              this.$emit('onClose');
              this.$emit('onGetList');
            });
        }
      });
    },
  },
  created() {
    this.getFormRule('sfa_attendance_check_in_details_report_add_clock');
  },
};
</script>
