<script>
import Form, { formCreate } from '../../../../../components/form';
import Edit from './components/edit.vue';
import request from '../../../../../utils/request';

formCreate.component('sfaAttendanceManageEdit', Edit);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: true,
      },
    };
  },
  methods: {
    submit() {
      const formData = this.getFormData();
      const requestUrl = '/sfa/sfaSignFormsController/updateBatchSignList';
      const ids = [];
      formData.editTable.forEach((item) => {
        ids.push(item.id);
      });
      const sfaBatchReqVos = formData.editTable.map((item) => ({
        id: item.id,
        sfaSignTime: item.sfaSignTime,
      }));
      const params = {
        sfaBatchReqVos,
        workSignStatus: formData.signStatus,
        wsPlaceStatus: formData.addStatus,
      };
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },

  created() {
    this.getFormRule('sfa_attendance_check_in_details_report_edit')
      .then(() => {
        this.setValue({
          editTable: this.formConfig.row,
        });
      });
  },
};
</script>
