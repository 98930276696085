<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="人员姓名">
          <el-input v-model="wsRealName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="账户">
          <el-input v-model="wsUserName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="职位">
          <el-input v-model="wsPosName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="区域">
          <el-input v-model="wsParentOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="办事处">
          <el-input v-model="wsOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="打卡类型">
          <el-input v-model="workSignDesc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="考勤时间">
          <el-input v-model="workSignTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="考勤地点">
          <el-input v-model="workSignPlace" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="状态">
          <el-input v-model="workSignStatusDesc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="规则编码">
          <el-input v-model="ruleCode" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="规则名称">
          <el-input v-model="ruleName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="规则类型">
          <el-input v-model="ruleTypeDesc" readonly></el-input>
        </el-form-item>
      </el-col>
       <el-col :span="24">
        <el-form-item label="备注">
          <el-input v-model="remarks" type="textarea" :rows="2" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="img-url">
        <el-form-item label="照片">
          <el-image
          :preview-src-list="signPictures"
          v-for="(item, index) in signPictures"
          :key="index"
          :src="item"
        ></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          wsRealName: '', // 人员姓名
          wsUserName: '', // 账户
          wsPosName: '', // 职位
          wsParentOrgName: '', // 区域
          wsOrgName: '', // 办事处
          workSignDesc: '', // 打卡类型
          workSignTime: '', // 考勤时间
          workSignPlace: '', // 考勤地点
          workSignStatusDesc: '', // 状态
          ruleCode: '', // 规则编码
          ruleName: '', // 规则名称
          ruleTypeDesc: '', // 规则类型
          remarks: '', // 备注
          picUrl: '', // 照片
          picPath: '',
          signPictures: [],
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.wsRealName = val.wsRealName;
          this.wsUserName = val.wsUserName;
          this.wsPosName = val.wsPosName;
          this.wsParentOrgName = val.wsParentOrgName;
          this.wsOrgName = val.wsOrgName;
          this.workSignDesc = val.workSignDesc;
          this.workSignTime = val.workSignTime;
          this.workSignPlace = val.workSignPlace;
          this.workSignStatusDesc = val.workSignStatusDesc;
          this.ruleCode = val.ruleCode;
          this.ruleName = val.ruleName;
          this.ruleTypeDesc = val.ruleTypeDesc;
          this.remarks = val.remarks;
          this.picUrl = val.picUrl;
          this.picPath = val.picPath;
          const signPictures = val.signPictures ? val.signPictures : [];
          this.signPictures = [];
          signPictures.forEach((item) => {
            this.signPictures.push(item.picUrl + item.picPath);
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      wsRealName: '', // 人员姓名
      wsUserName: '', // 账户
      wsPosName: '', // 职位
      wsParentOrgName: '', // 区域
      wsOrgName: '', // 办事处
      workSignDesc: '', // 打卡类型
      workSignTime: '', // 考勤时间
      workSignPlace: '', // 考勤地点
      workSignStatusDesc: '', // 状态
      ruleCode: '', // 规则编码
      ruleName: '', // 规则名称
      ruleTypeDesc: '', // 规则类型
      remarks: '', // 备注
      imgUrl: '',
      signPictures: [],
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/ .el-image__inner,
  /deep/ .el-image__error {
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }

  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
